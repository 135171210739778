#c {
    background-color:#050E1D;
    background-image: linear-gradient(white 2px, transparent 2px),
      linear-gradient(90deg, white 2px, transparent 2px),
      linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    background-size:100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;
    padding: 0;
}

.set-btn {
    background-color: #142443;
    color: #fff;
    font-size: 18px;
    border: 1px solid;
    width: 155px;
    height: 34px;
    margin: 0em 0em 1em 0em;
    border-radius: 5px;
    padding-top: 0.1em;
}

.set-btn:hover {
    background-color: #20B604;
    color: #fff;
}

.set-btn:focus {
    background-color: #222;
    color: #fff;
}

.main-btn {
    color: #fff;
    border: 1px solid;
    width: 45px;
    height: 33px;
    margin-right: 0.5em;
    font-size: 18.5px;
    font-family: 'IBM Plex Sans', sans-serif;
    border-radius: 10px;
    text-align: center;
    position: relative;
    padding-bottom: 1em;
}

.main-btn:hover {
    font-weight: 600;
    background-color: #25388F;
    color: #fff;
}

.update-btn {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 88px;
    height: 25px;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 13px;
    border-radius: 5px;
}

.area-btn {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 68px;
    height: 25px;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 13px;
    border-radius: 5px;
}

.area-btn:hover, #newLength:hover, #newArea:hover, #newCount:hover, .update-btn:hover {
    background-color: #fff;
    color: #222;
    font-weight: 600;
}


#length-table, #length-table1, #length-table2, #length-table3, #length-table4, #count-table, #count-table1, #count-table2, #count-table3, #count-table4 {
    color: #fff;
    font-size: 15px;
    width: 100%;
    margin-top: 0.05em;
    overflow-x: auto;
}

#length-table tr th, #length-table1 tr th, #length-table2 tr th, #length-table3 tr th, #length-table4 tr th {
    margin: 0;
    text-align: left;
    padding: 1.5em 0em 0.3em 0em;
    border-bottom: 1px solid #fff;
    font-size: 14px;
}

#area-table tr th, #area-table1 tr th, #area-table2 tr th, #area-table3 tr th, #area-table4 tr th {
    margin: 0;
    text-align: left !important;
    padding: 0em 0em 0.3em 0em;
    border-bottom: 1px solid #fff;
    font-size: 14px;
}

#count-table tr th, #count-table1 tr th, #count-table2 tr th, #count-table3 tr th, #count-table4 tr th {
    margin: 0;
    text-align: left !important;
    padding: 1em 0em 0.3em 0em;
    border-bottom: 1px solid #fff;
    font-size: 14px;
}

#area-table, #area-table1, #area-table2, #area-table3, #area-table4 {
    color: #fff;
    font-size: 15px;
    width: 100%;
    margin-top: -0.5em;
    overflow-x: auto;
}

#area-table tr, #area-table1 tr, #area-table2 tr, #area-table3 tr, #area-table4 tr, #length-table tr, #length-table1 tr, #length-table2 tr, #length-table3 tr, #length-table4 tr {
    border-bottom: 1px solid #fff;
    width: 100%;
    text-align: left;
}

#count-table tr, #count-table1 tr, #count-table2 tr, #count-table3 tr, #count-table4 tr {
    border-bottom: 1px solid #fff;
    width: 100%;
    text-align: left;
}

#costrate-label, #costrate-label1, #costrate-label2, #costrate-label3, #costrate-label4 {
    color: #fff;
    font-size: 13.5px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 1em;
}

#costarea-label, #costarea-label1, #costarea-label2, #costarea-label3, #costarea-label4, #length-label, #length-label1, #length-label2, #length-label3, #length-label4, #area-label, #area-label1, #area-label2, #area-label3, #area-label4  {
    color: #fff;
    font-size: 13.5px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 1em;
    margin-right: 0.3em;
}

#costcount-label, #costcount-label1, #costcount-label2, #costcount-label3, #costcount-label4, #count-label, #count-label1, #count-label2, #count-label3, #count-label4 {
    color: #fff;
    font-size: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 1em;
    margin-right: 0.3em;
}

#calibrator-label {
    color: #fff;
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
    padding-right: 0.5em;
    font-weight: 600;
}

#user-length {
    padding: 0;
    width: 180px;
    margin-right: 0.5em;
    margin-bottom: 1em;
}

#costrate, #costrate1, #costrate2, #costrate3, #costrate4 {
    height: 26px;
    width: 62px;
    margin: 0 0.5em 1em 0.25em;
}

#costarea, #costarea1, #costarea2, #costarea3, #costarea4 {
    width: 62px;
    margin-right: 1em;
}

#costcount, #costcount1, #costcount2, #costcount3, #costcount4 {
    width: 62px;
    margin-right: 0.5em;
}

#count-type, #count-type1, #count-type2, #count-type3, #count-type4, #length-type, #length-type1, #length-type2, #length-type3, #length-type4, #area-type, #area-type1, #area-type2, #area-type3, #area-type4 {
    height: 26px;
    width: 110px;
    margin-bottom: 0.3em;
    margin-right: 0.5em;
}

#file {
    color: #fff;
    padding: 0.5em 0em 1.5em 0em;
    margin-left: 4em;
    font-size: 16px;
}

#measure-type {
    margin-left: 0.5em;
    margin-right: 0.75em;
    background-color: #222;
    color: #fff;
}

.main-features {
    padding-bottom: 1em;
    align-items: center;
    text-align: center;
    margin: auto;
    display: inline-block;
}

.length-section {
    padding: 0.65em 0.5em 0em 0.5em;
    margin-bottom: 0.5em;
    border: 2px solid #fff;
    background-color: #132955;
    border-radius: 8px;
    overflow-x: auto;
}

.length-buttons, .count-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.area-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 1em;
}

.area-section {
    border: 2px solid #fff;
    padding: 0.5em 0.5em 0em 0.5em;
    background-color: #132955;
    margin-bottom: 0.5em;
    border-radius: 8px;
    overflow-x: 
    auto;
}

.count-section {
    border: 2px solid #fff;
    padding: 0.6em 0.5em 0em 0.5em;
    background-color: #132955;
    margin-bottom: 0.5em;
    border-radius: 8px;
    overflow-x: auto;
}

.length-features, .area-features, .count-features, .upload-features {
    height: auto;
    border: 1px solid #fff;
    padding: 0.8em 0.5em 0.25em 0.5em;
    background-color: #132955;
    align-content: center;
    text-align: center;
}

#total-text, #total-text1, #total-text2, #total-text3, #total-text4 {
    color: #fff;
    font-size: 17px;
    padding-top: 1.3em;
}

#export-button, #export-count, #export-area {
    padding: 0;
    margin: 0;
    text-align: center;
    text-decoration: none !important;
    color: #fff;
}

.export {
    background-color: #142443;
    color: #fff;
    font-size: 16px;
    border: 1px solid;
    width: 70px;
    height: 25px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 0.5em;
    border-radius: 5px;
}

#delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4, #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 24px;
    height: 24px;
    font-size: 13px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    border-radius: 10px;
    position:absolute; 
    left: 24%; 
    margin-top: -0.9em;
}

#delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 24px;
    height: 24px;
    font-size: 13px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    border-radius: 10px;
    position:absolute; 
    left: 24%; 
    margin-top: -0.75em;
}

#newLength, #newArea, #newCount {
    background-color: #142443;
    color: #fff;
    font-size: 15px;
    border: 1px solid;
    width: 125px;
    height: 25px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 0.7em;
    border-radius: 5px;
    margin-right: 1em;
}

#modal {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    left: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    height: 100%;
    width: 100%;
}

.modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #132955;
    width: 40%;
    height: 40%;
    border-radius: 15px;
    align-content: center;
    border: 2.5px solid #fff;
}

.modal_content h1 {
    padding-top: 0.5em;
    font-size: 2.5vw;
}

#file_modal {
    color: #fff;
    padding: 2em 0em 0em 0em;
    position: absolute;
    left: 29%;
    font-size: 1.3vw;
    text-align: center;
}

.modal-title {
    color: #fff;
    text-align: center;
    font-size: 1.7vw;
    font-family: 'IBM Plex Sans', sans-serif;
    padding-top: 0.5em;
}

#modal-btn {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.0);
    border: none;
    margin-top: -0.1em;
    width: 35px;
    height: 35px;
    font-size: 21px;
    position:absolute; 
    right:0; 
}

#done-step2, #done-step3 {
    background-color: #142443;
    color: #fff;
    font-size: 18px;
    border: 1px solid;
    width: 100px;
    height: 30px;
    padding: 0;
    margin-bottom: 0.5em;
    border-radius: 5px;
}

#done-step2:hover, #done-step3:hover {
    background-color: #20B604;
    color: #fff;
}

#calibrate-step1 p, #calibrate-step2 p, #calibrate-step3 p, #calibrate-step4 p {
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-top: 0.25em;
}

.instructions {
    padding: 1em 0 0 1em;
}

.instructions p {
    text-align: left;
    font-size: 13.5px;
    font-family: 'IBM Plex Sans', sans-serif;
    padding-bottom: 0;
}

.instructions h3 {
    color: #fff;
    font-size: 17px;
    padding-left: 0em;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
}

#calibrate-step3 {
    align-content: center;
}

.table-del {
    background: #132955 !important;
    color: #fff;
    border: none;
    font-weight: 600;
    font-size: 14px;
    height: 22.5px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
  
.main-btn .tooltiptext {
    visibility: hidden;
    height: 20px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 13px;
    color: #111;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: 90%;
    left: 75%;
}
  
.main-btn:hover .tooltiptext {
    visibility: visible;
}

.sp-replacer {
    margin-top: -3.7em;
    visibility: hidden;
}

.sp-container {
    border-color: grey;
}
  
.sp-thumb {
    border-color: grey;
}

@media screen and (min-width: 1571px) {
    #delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4, #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
        position: absolute; 
        left: 25%; 
    }
    
    #delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
        position: absolute; 
        left: 25%; 
    }
}

@media screen and (max-width: 1205px) {
    #delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4, #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
        position: absolute; 
        left: 23.2%;
    }
    
    #delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
        position: absolute; 
        left: 23.2%;
    }
}


@media screen and (max-width: 1020px) {
    #file {
        font-size: 12px;
        margin-left: 0.5em;
    }

    .main-btn {
        width: 30px;
        height: 20px;
        font-size: 12px;
    }

    #delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4, #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
        position: absolute; 
        left: 22.3%; 
    }
    
    #delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
        position: absolute; 
        left: 22.3%; 
    }
}

@media screen and (max-width: 768px) {

    .modal-title {
        font-size: 18px;
    }

    #file_modal {
        font-size: 13px;
        left: 18px;
    }

    .main-btn {
        width: 30px;
        height: 20px;
        font-size: 12px;
    }

    #file {
        font-size: 12px;
        margin-left: 0;
    }

    .set-btn {
        font-size: 17px;
        width: 85px;
        height: 34px;
    }

    #newLength, #newArea, #newCount {
        font-size: 11.5px;
        width: 90px;
        height: 25px;
    }

    .export {
        font-size: 13px;
        width: 50px;
        height: 25px;
    }

    .instructions p {
        font-size: 12px;
    }
    
    .instructions h3 {
        font-size: 14px;
    }

    #area-table tr th, #area-table1 tr th, #area-table2 tr th, #area-table3 tr th, #area-table4 tr th {
        font-size: 8.5px;
    }
    
    #length-table tr th, #length-table1 tr th, #length-table2 tr th, #length-table3 tr th, #length-table4 tr th {
        font-size: 8.5px;
    }
    
    #count-table tr th, #count-table1 tr th, #count-table2 thr th, #count-table3 tr th, #count-table4 tr th {
        font-size: 8.5px;
    }

    #length-table, #length-table1, #length-table2, #length-table3, #length-table4, #count-table, #count-table1, #count-table2, #count-table3, #count-table4 {
        font-size: 10px;
        overflow-x: auto;
    }
    
    #area-table, #area-table1, #area-table2, #area-table3, #area-table4 {
        font-size: 10px;
        overflow-x: auto;
    }

    #export-area {
        font-size: 12px;
    }

    #user-length {
        width: 100px;
        margin-bottom: 1em;
        padding: 0;
    }

    #count-type, #count-type1, #count-type2, #count-type3, #count-type4, #length-type, #length-type1, #length-type2, #length-type3, #length-type4, #area-type, #area-type1, #area-type2, #area-type3, #area-type4  {
        height: 23px;
        width: 80px;
        margin: 0;
    }

    #area-text, #area-text1, #area-text2, #area-text3, #area-text4 {
        color: #fff;
        font-size: 15px;
        padding: 1.5em 0em 0em 0em;
    }

    .table-del {
        font-size: 10px;
        margin-left: -1em;
    }

    .area-btn, .update-btn {
        font-size: 11px;
        margin-bottom: 0.5em;
    }

    #calibrate-step1 p, #calibrate-step2 p, #calibrate-step3 p, #calibrate-step4 p {
        font-size: 13px;
        font-family: 'IBM Plex Sans', sans-serif;
        margin-top: 0.25em;
    }

    #done-step2, #done-step3 {
        font-size: 16px;
        width: 75px;
        height: 28px;
    }

    #calibrator-label {
        font-size: 13px;
    }

    #user-length {
        width: 85px;
    }

    .modal_content h1 {
        padding-top: 1em;
    }

    #total-text, #total-text1, #total-text2, #total-text3, #total-text4 {
        font-size: 15px;
    }

    #costarea-label, #costarea-label1, #costarea-label2, #costarea-label3, #costarea-label4, #count-label, #count-label1, #count-label2, #count-label3, #count-label4 {
        font-size: 13px;
    }

    #costarea, #costarea1, #costarea2, #costarea3, #costarea4, #count-type, #count-type1, #count-type2, #count-type3, #count-type4 {
        margin-bottom: 0.5em;
    }

    #costcount-label, #costcount-label1, #costcount-label2, #costcount-label3, #costcount-label4 {
        font-size: 13px;
        margin-bottom: 0.5em;
    }

    #delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4, #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
        position: absolute;
        left: 21.5%;
    }
    
    #delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
        position: absolute; 
        left: 21.5%; 
    }
    
}

@media screen and (max-width: 675px) {
    #delete-button, #delete-button1, #delete-button2, #delete-button3, #delete-button4 {
        position: absolute; 
        left: 20%; 
    }

    #delete-count, #delete-count1, #delete-count2, #delete-count3, #delete-count4 {
        position: absolute; 
        left: 20.5%; 
    }
    
    #delete-area, #delete-area1, #delete-area2, #delete-area3, #delete-area4 {
        position: absolute; 
        left: 20.2%; 
    }
}


@media screen and (max-width: 498px) {
    .row {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }    
}
