.hero-container {
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
    height: 650px;
    position: relative;
    z-index: 1;
}


.hero-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 2;
}

.hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero-vid {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}

.hero-content {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    margin-top: 0em;
    flex-direction: column;
    align-items: center;
}

.hero-content h1 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    font-family: 'Play', sans-serif;
    font-weight: 600;
}

.hero-content h2 {
    color: #fff;
    font-size: 22px;
    font-family: 'Encode Sans', sans-serif;
    margin-top: 1em;
}


.hero-btn-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-btn {
    border-radius: 10px;
    background:#222;
    color: #fff;
    padding-bottom: 0.2em;
    width: 180px;
    height: 42px;
    font-size: 21px;
    outline: none;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Exo 2', sans-serif;
    text-decoration: none !important;
}

.hero-btn:hover {
    background: #fff;
    color: #111;
}

@media screen and (max-width: 768px) {
    .hero-content h1 {
        color: #fff;
        font-size: 40px;
        text-align: center;
    }
    
    .hero-content h2 {
        margin-top: 20px;
        color: #fff;
        text-align: center;
        font-size: 20px;
        max-width: 600px;
    }
}

@media screen and (max-width: 480px) {
    .hero-content h1 {
        color: #fff;
        font-size: 28px;
        text-align: center;
    }
    
    .hero-content h2 {
        color: #fff;
        font-size: 17px;
    }
}