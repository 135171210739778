* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.login, .register {
    background-color: #061229 !important;
    color: #fff;
    height: 700px;
    width: 100%;
    padding-top: 0.75em;
    margin: 0;
    text-align: center;
}

.login_title {
    text-align: center;
    padding: 2em 1.75em 1em 0em;
    font-family: 'Play', sans-serif;
    font-size: 40px;
}

.login_form {
    align-content: center;
    text-align: center;
    display: inline-block;
    padding: 0;
    width: 50%;
}

#loginbox {
    width: 100%;
}

#login_submit {
    margin-top: 2em;
    width: 35%;
    font-size: 18px;
}

.login_link {
    text-decoration: none !important;
    color: #fff !important;
    font-family: 'Play', sans-serif;
    font-size: 25px;
}

.login_link:hover {
    color: #18CAE6 !important;
}


@media screen and (max-width: 768px) {
  
    .about-us p, .apps-op p {
      font-size: 9.25px;
    }
  
    .about-us-content-logos h1 {
        font-size: 45px;
        padding-bottom: 1.2em;
    }
  
    .about-us-content-logos h2 {
        font-size: 18px;
        padding-bottom: 3em;
    }
  
    .about-us-content-logos h3 {
        font-size: 20px;
        padding-bottom: 3em;
    }
  
    .about-us-content-logos h4 {
        font-size: 25px;
    }
  
    .about-us-content-logos h5 {
        font-size: 20px;
        margin-top: -15px;
    }
  
}

@media screen and (max-width: 480px) {
    
    #login_submit {
        margin-top: 2em;
        width: 40%;
        font-size: 14px;
    }

    .login_link {
        font-size: 20px;
    }
}
  