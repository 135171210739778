.container-fluid {
    background-color: #142443;
    align-items: center;
    text-align: center;
}

.row {
    padding: 0;
    margin: 0;   
}

.column1 {
    width: 27%;
    height: auto;
    overflow-x: hidden !important;
}

.column2 {
    width: 73%;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;
    height: auto;
}

.container-fluid h1 {
    text-align: center;
    color: #fff;
    font-size: 2.25vw;
    padding: 0.15em 0em 0em 0em;
    font-family: 'DM Sans', sans-serif;
}

.container-fluid p {
    color: #fff;
    text-align: center;
    font-size: 0.75vw;
    margin-top: -0.6em;
    font-family: 'IBM Plex Sans', sans-serif;
}

.label_costRate {
    color: #fff;
    padding-right: 0.5em;
}

.service-bar {
    background-color: #061229 !important;
    color: #fff;
    border-bottom: 0px;
}

.tab-bar {
    background-color: #142443 !important;
    color: #fff;
    height: 30px;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.service-bar a {
    background-color: #fff;
    color: #fff;
    height: 46px;
    font-size: 16.5px;
    font-weight: 600;
}

.tab-bar a {
    background-color: #fff;
    color: #fff;
    height: 30px;
    padding-top: 0.1em;
    padding-left: 1em;
    font-size: 14.5px;
    font-family: 'IBM Plex Sans', sans-serif;
}

.service-bar a:hover {
    color: #fff;
}

.tab-bar a:hover {
    color: #fff;
    height: 30px !important;
    font-weight: 300;
}

.mobile {
    background-color: #142443;
    color: #fff;
    height: 700px;
}

#mobile-text {
    padding-top: 8em;
    font-size: 25px;
}

@media screen and (min-height: 776px) {
    .column1, .column2 {
        padding-bottom: 15em;
    }
}

@media screen and (min-height: 1000px) {
    .column1, .column2 {
        padding-bottom: 25em;
    }
}

@media screen and (min-height: 1150px) {
    .column1, .column2 {
        padding-bottom: 35em;
    }
}

@media screen and (min-height: 1300px) {
    .column1, .column2 {
        padding-bottom: 45em;
    }
}

@media screen and (max-width: 1520px) {
    .tab-bar a {
        padding-top: 0.1em;
        padding-left: 0.69em;
        font-size: 14.5px;
        font-family: 'IBM Plex Sans', sans-serif;
    }
}

@media screen and (max-width: 1448px) {
    .tab-bar a {
        padding-top: 0.2em;
        padding-left: 0.69em;
        font-size: 13.5px;
        font-family: 'IBM Plex Sans', sans-serif;
    }
}

@media screen and (max-width: 1376px) {
    .tab-bar a {
        padding-top: 0.3em;
        padding-left: 0.69em;
        font-size: 12px;
        font-family: 'IBM Plex Sans', sans-serif;
    }
}

@media screen and (max-width: 1267px) {
    .tab-bar {
        background-color: #142443 !important;
        color: #fff;
        height: 30px;
        padding: 0;
        margin: 0;
        font-weight: 600;
        display: inline;
    }

    .tab-bar a {
        font-size: 13px;
        padding-top: 0.3em;
    }
}


@media screen and (max-width: 550px) {
    .service-bar a {
        font-size: 12px;
    }
}

