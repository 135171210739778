.services-container {
    background: linear-gradient(290deg, rgba(175,188,210,1) 14.5%, rgba(6,18,41,1) 14.5%, rgba(6,18,41,1) 91%, rgba(175,188,210,1) 91%);
    height: 925px;
    padding-top: 2em;
}

.webservice-container {
    background: linear-gradient(290deg, rgba(175,188,210,1) 14.5%, rgba(6,18,41,1) 14.5%, rgba(6,18,41,1) 91%, rgba(175,188,210,1) 91%);
    height: 700px;
    padding-top: 0em;
}

.qs-container {
    color: #fff;
    background: url('../../images/blue-grid.jpg') center center/cover no-repeat;
}

.mw-container {
    color: #fff;
    background: url('../../images/sunset.jpg') center center/cover no-repeat;
}

.op-container {
    background-color:#016FBD;
}

.wb-container {
    background: linear-gradient(70deg, rgba(175,188,210,1) 9%, rgba(6,18,41,1) 9%, rgba(6,18,41,1) 85.5%, rgba(175,188,210,1) 85.5%);
}

.about-container {
    color: #fff;
    background-color: #010606;
}

.services-info-wrapper {
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 0 24px;
    justify-content: center;
    display: grid;
}

.qs-info-wrapper {
    z-index: 1;
    height: 275px;
    width: 100%;
    padding: 0 24px;
    justify-content: center;
    display: grid;
}

.services-wrapper {
    padding: 3em 1em 1em 1em;
}

.mw-heading {
    margin-left: 5em;
    margin-top: 0.2em;
    padding: 0;
    font-size: 52px;
    color: #fff;
    font-family: 'Audiowide';
}

.qs-heading {
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    padding: 0.5em 0 0em 0em;
    position: auto;
    text-align: center;
    font-size: 55px;
}

.qs-subtitle {
    padding: 0em 0 1em 0em;
    position: auto;
    text-align: center;
    color: #fff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
}

.services-heading1 {
    text-align: center;
    position: auto;
    font-size: 35px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 600;
}

.services-subtitle {
    font-size: 17.5px;
    line-height: 23px;
    color: #fff; 
    font-family: 'IBM Plex Sans', sans-serif;
    margin-top: 1.5em;
    padding: 1em 1em;
    position: auto;
    text-align: left;
    max-width: 900px;
}

.coming-soon {
    color: #fff;
    font-family: 'Teko', sans-serif;
    font-size: 55px;
    text-align: center;
}

.app-btn {
    border-radius: 2px;
    background:#222;
    color: #fff;
    padding-bottom: 0.2em;
    width: 180px;
    height: 42px;
    font-size: 21px;
    outline: none;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Exo 2', sans-serif;
    text-decoration: none !important;
}

.qs-btn {
    border-radius: 2px;
    background:#222;
    color: #fff;
    padding-bottom: 0.2em;
    margin-left: 4.35em;
    width: 180px;
    height: 45px;
    font-size: 20px;
    outline: none;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Exo 2', sans-serif;
    text-decoration: none !important;
}

.app-btn:hover, .qs-btn:hover {
    background: #fff;
    color: #111;
}

.icon {
    fill: #fff !important;
}

.about-us-logos h1 {
    text-align: center;
    color: #fff;
    font-family: 'Audiowide';
    font-size: 45px;
    padding-bottom: 0.45em;
}
  
.about-us-logos h2 {
    text-align: center;
    color: #fff;
    font-family: 'Montserrat Subrayada', sans-serif;
    font-size: 35px;
    padding-bottom: 1em;
}
  
.about-us-logos h3 {
    text-align: center;
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    font-size: 45px;
    padding-bottom: 1em;
}
  
.about-us-logos h4 {
    text-align: center;
    color: #fff;
    font-family: 'Anton', sans-serif;
    font-size: 45px;
}
  
.about-us-logos h5 {
    text-align: center;
    color: #fff;
    font-family: 'Teko', sans-serif;
    font-size: 35px;
    margin-top: -15px;
}

@media screen and (max-width: 768px) {

    .info-wrapper {
        height: 700px;
    }

    .services-container {
        height: 1100px;
    }

    .webservice-container {
        height: 875px;
    }

    .services-info-wrapper {
        height: 900px;
    }

    .services-heading1 {
        margin-top: 0em;
        font-size: 32px;
    }
    
    .services-subtitle {
        font-size: 16px;
        line-height: 23px;
    }
    
    .coming-soon {
        color: #fff;
        font-family: 'Teko', sans-serif;
        font-size: 45px;
    }

    .about-us-logos h1 {
        font-size: 45px;
        padding-bottom: 1.2em;
    }
  
    .about-us-logos h2 {
        font-size: 18px;
        padding-bottom: 3em;
    }
  
    .about-us-logos h3 {
        font-size: 20px;
        padding-bottom: 3em;
    }
  
    .about-us-logos h4 {
        font-size: 25px;
    }
  
    .about-us-logos h5 {
        font-size: 20px;
        margin-top: -15px;
    }

    .qs-heading {
        font-size: 55px;
    }
    
    .qs-subtitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {

    .services-container {
        padding-top: 0em;
        height: 1350px;
    }

    .webservice-container {
        height: 950px;
    }

    .heading {
        font-size: 32px;
    }

    .services-subtitle {
        margin-top: -1em;
        font-size: 14px;
        line-height: 21px;
    }

    .services-heading1 {
        margin-top: 0em;
        font-size: 28px;
    }
    
    .coming-soon {
        color: #fff;
        font-family: 'Teko', sans-serif;
        font-size: 35px;
        margin-top: -1em;
    }

    .about-us-logos h1 {
        font-size: 25px;
        margin: 0;
    }
  
    .about-us-logos h2 {
        font-size: 18px;
        padding: 0;
    }
  
    .about-us-logos h3 {
        font-size: 19px;
        padding: 0;
    }
  
    .about-us-logos h4 {
        font-size: 21px;
    }
  
    .about-us-logos h5 {
        font-size: 19px;
        margin-top: -0.5em;
    }

    .qs-heading {
        font-size: 40px;
    }
    
    .qs-subtitle {
        font-size: 16px;
    }

    .qs-btn {
        border-radius: 2px;
        background:#222;
        color: #fff;
        padding-bottom: 0.2em;
        margin-left: 1em;
        width: 200px;
        height: 45px;
        font-size: 24px;
        outline: none;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: 'Exo 2', sans-serif;
    }

    @media screen and (max-width: 375px) {

        .services-info-wrapper {
            height: 1200px;
        }

        .webservice-container {
            height: 1350px;
        }
    }

}