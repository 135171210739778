.fa-bars {
  display: none;
}

.fa-times {
  display: none;
}
  
.menu-icon {
  display: none;
}

.icon {
  fill: #fff !important;
}

#dropdown-btn {
  background: #000 !important;
  font-size: 18px;
  color: #fff;
  border: 0px !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 19px;
  font-family: 'Exo 2', sans-serif;
  margin-top: 1.6em;
}

#dropdown-btn:hover {
  color: #18CAE6;
}

.dropdown-menu {
  background: rgb(26, 23, 23);
  text-align: center;
  width: 220px;
  align-content: center;
}

.dropdown-divider {
  background-color: #fff !important;
}

.dropdown-item {
  background: rgb(26, 23, 23);
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  text-decoration: none !important;
  font-size: 20px;
  padding: 0em 0em 0em 0.75em;
  text-align: center;
  margin: 0;
}

.dropdown-item:hover {
  color: #18CAE6;
  background: rgb(26, 23, 23);
}

#dropdown-mw {
  font-family: 'Montserrat Subrayada', sans-serif;
  font-size: 19px;
}

#dropdown-qs {
  font-family: 'DM Sans', sans-serif;
  margin-top: -0.5em;
  margin-bottom: 0.7em;
}

#dropdown-apps {
  font-family: 'Exo 2', sans-serif;
  padding-left: 3.5em;
}


@media screen and (max-width: 768px) {
   
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-80%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .icon {
    fill: #fff !important;
  }
          
  .fa-bars {
    color: #fff;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1rem;
    cursor: pointer;
  }
              
}