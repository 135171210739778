.disclaimer {
    align-content: center;
    padding-left: 0.5em;
    padding-right: 0em;
    padding: 0 0 0 0.5;
}

.disclaimer p {
    font-size: 11px;
    font-family: 'IBM Plex Sans', sans-serif;
}

.disclaimer h2 {
    color: #fff;
    font-size: 22px;
    padding: 0.5em 0 0 1.5em;
    font-family: 'IBM Plex Sans', sans-serif !important;
}

.set-btn {
    background-color: #142443;
    color: #fff;
    font-size: 20px;
    border: 1px solid;
    width: 180px;
    height: 35px;
    margin: 0.3em 0em 1.5em 5.5em;
    padding-bottom: 0.3em;
}

.set-btn:hover {
    background-color: #20B604;
    color: #fff;
}

.set-btn:focus {
    background-color: #222;
    color: #fff;
}

.main-btn {
    background-color: #132955;
    color: #fff;
    border: 1px solid;
    width: 93px;
    height: 26.5px;
    margin-right: 0.6em;
    font-size: 15.5px;
    font-family: 'IBM Plex Sans', sans-serif;
}

.main-btn:hover {
    background-color: #fff;
    color: #222;
    font-weight: 600;
}

.main-btn:focus {
    background-color: #222;
    color: #fff;
    font-weight: 600;
}

.area-btn {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 95px;
    height: 27px;
    margin-top: -0.5em;
    margin-right: 2.4em;
    font-family: 'IBM Plex Sans', sans-serif;
}

.area-btn:hover {
    background-color: #fff;
    color: #222;
}


#count-type {
    width: 150px;
    margin-right: 3.5em;
}

#file {
    color: #fff;
    padding: 0.5em 0em 2em 0em;
    margin-left: 4em;
    font-size: 18.5px;
}

#measure-type {
    margin-left: 0.5em;
    margin-right: 0.75em;
    background-color: #222;
    color: #fff;
}

.main-features {
    padding-top: 0em;
    padding-bottom: 1.5em;
}

.length-section {
    padding: 1.3em 0.5em 0.5em 0.5em;
    border: 1px solid #fff;
    background-color: #132955
}

.area-section {
    border: 1px solid #fff;
    padding: 1em 0.5em 1.5em 1em;
    background-color: #132955
}

.count-section {
    border: 1px solid #fff;
    padding: 1.3em 0.5em 1.5em 1em;
    background-color: #132955
}

.home-features {
    height: 400px;
    border: 1px solid #fff;
    padding: 2em 0.5em 0em 1em;
    background-color: #132955
}

.home-features p {
    font-size: 14.5px;
    font-family: 'IBM Plex Sans', sans-serif;
}

#total-text {
    color: #fff;
    font-size: 24px;
    padding-top: 1em;
    padding-bottom: 0;
}

#export-button {
   text-decoration: underline !important;
   color: #fff; 
   padding-left: 2.2em;
   padding-right: 2.5em;
}

#export-area {
    text-decoration: underline !important;
    color: #fff;
    padding: 0 12em 0 1em;
}

#delete-button, #delete-area, #delete-count {
    background-color: #142443;
    color: #fff;
    border: 1px solid;
    width: 95px;
    height: 27px;
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
}

#delete {
    background-color:#132955;
    color: #fff;
    border: 1px solid;
    width: 113px;
    height: 26.5px;
    font-size: 14.8px;
    font-family: 'IBM Plex Sans', sans-serif;
}








