.table {
    color: #fff;
}

.table tr th {
    color: #fff;
}

.delete_btn {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
}

.clear_btn {
    background-color: #132B59;
    border-color: #fff;
    border: 1px solid;
    color: #fff;
    font-size: 20px;
}

.total {
    color: #fff;
}

.csv_btn {
    background-color: #132B59;
    color: #fff;
    border: 1px solid;
    color: #fff;
    width: 110px;
    height: 30px;
    margin-top: 20px;
}

.csv_btn:hover {
    background-color: #fff;
    color: #222;
}

.csv_link {
    color: #fff;
    text-decoration: none !important;
    font-size: 20px;
}

.csv_link:hover{
    color: #222;
}

